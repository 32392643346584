import React, { useState, useMemo,useEffect} from 'react';
import axios from 'axios';
import { Dialog, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import apiURL from './Config';

function numberToWords(num) {
    const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
    const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
    const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
    
    if (num === 0) return "Zero Only";
  
    // Split the number into groups following the Indian numbering system
    const crore = Math.floor(num / 10000000);
    const lakh = Math.floor((num % 10000000) / 100000);
    const thousand = Math.floor((num % 100000) / 1000);
    const hundred = Math.floor((num % 1000) / 100);
    const tensAndUnits = num % 100;
  
    let result = "";
  
    // Handle crores
    if (crore > 0) {
      result += convertGroupToWords(crore, units, teens, tens) + " Crore ";
    }
  
    // Handle lakhs
    if (lakh > 0) {
      result += convertGroupToWords(lakh, units, teens, tens) + " Lakh ";
    }
  
    // Handle thousands
    if (thousand > 0) {
      result += convertGroupToWords(thousand, units, teens, tens) + " Thousand ";
    }
  
    // Handle hundreds
    if (hundred > 0) {
      result += units[hundred] + " Hundred ";
    }
  
    // Handle tens and units
    if (tensAndUnits > 0) {
      if (tensAndUnits < 10) {
        result += units[tensAndUnits] + " ";
      } else if (tensAndUnits < 20) {
        result += teens[tensAndUnits - 10] + " ";
      } else {
        const tensValue = Math.floor(tensAndUnits / 10);
        const unitsValue = tensAndUnits % 10;
        result += tens[tensValue] + " " + units[unitsValue] + " ";
      }
    }
  
    return result.trim() + " Only";
  }
  
  // Helper function to convert groups to words (for crores, lakhs, and thousands)
  function convertGroupToWords(group, units, teens, tens) {
    if (group === 0) return "";
  
    let groupWord = "";
    if (group < 10) {
      groupWord += units[group];
    } else if (group < 20) {
      groupWord += teens[group - 10];
    } else {
      const tensValue = Math.floor(group / 10);
      const unitsValue = group % 10;
      groupWord += tens[tensValue] + " " + units[unitsValue];
    }
  
    return groupWord.trim();
  }
  
function ReportFilter() {
    const [startDate, setStartDate] = useState('');
    const [endDate, setEndDate] = useState('');
    const [month, setMonth] = useState('');
    const [year, setYear] = useState('');
    const [data, setData] = useState([]);
    const [open, setOpen] = useState(false);
    const [cgstRate, setCgstRate] = useState(0.09); // Define cgstRate state
    const [sgstRate, setSgstRate] = useState(0.09); // Define sgstRate state
    const [fuelExpense, setFuelExpense] = useState(0); // Define fuelExpense state
    const [period, setPeriod] = useState(''); // State to store the PERIOD text
    const [billNo, setBillNo] = useState('');
    const [editData, setEditData] = useState(null); // Track row data being edited
    const [isEditing, setIsEditing] = useState(false); // Track edit mode
    const [selectedRowIndex, setSelectedRowIndex] = useState(null); // Track selected row index
    const handleMonthChange = (e) => {
        setMonth(e.target.value);
    };

    const handleYearChange = (e) => {
        setYear(e.target.value);
    };
const handleDateRange = (range) => {
    if (month && year) {
        let startDay, endDay;
        let periodSuffix = '';

        if (range === '1-15') {
            startDay = '01';
            endDay = '15';
            periodSuffix = '-1'; // For 1 to 15 days
        } else if (range === '16-31') {
            startDay = '16';
            endDay = '31';
            periodSuffix = '-2'; // For 16 to 31 days
        }

        const start = `${year}-${month}-${startDay}`;
        const end = `${year}-${month}-${endDay}`;

        // Set the custom period based on the selected month and year
        const monthName = getMonthName(month);
        setPeriod(`${monthName}${year}${periodSuffix}`);

        // Fetch the data
        axios.get(`${apiURL}/api/get-exported-data`, { params: { startDate: start, endDate: end } })
            .then(response => {
                console.log('Data fetched successfully:', response.data);
                setData(response.data); // Update the data state
            })
            .catch(error => {
                console.error('Error fetching data:', error);
            });     
    } else {
        alert('Please select both month and year');
    }
};

// Function to get the month name from the month value (01 -> JAN, etc.)
const getMonthName = (month) => {
    const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 
        'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    return monthNames[parseInt(month) - 1];
};

// Set the period for the custom start and end dates
// Set the period for the custom start and end dates
const setCustomPeriod = (startDate, endDate) => {
    const start = new Date(startDate);
    const end = new Date(endDate);

    // Extract year and month from the start date
    const year = start.getFullYear();
    const startMonth = String(start.getMonth() + 1).padStart(2, '0'); // Get month as "01", "02", etc.
    const monthName = getMonthName(startMonth);

    // Get the start and end day
    const startDay = start.getDate();
    const endDay = end.getDate();

    let periodSuffix = '';

    // Check if the start and end days fall within the defined ranges
    if (startDay === 1 && endDay === 15) {
        periodSuffix = '-1'; // 1 to 15 days only
    } else if (startDay === 16 && endDay === 31) {
        periodSuffix = '-2'; // 16 to 31 days only
    } 
    // Set the period (e.g., "JAN2024-1" for January 1-15, 2024)
    setPeriod(`${monthName}${year}${periodSuffix}`);
};

const handleSubmit = async (e) => {
    e.preventDefault();

    if (startDate && endDate) {
        console.log('Start Date:', startDate);
        console.log('End Date:', endDate);

        // Set the custom period based on the selected start and end dates
        setCustomPeriod(startDate, endDate);

        // Fetch filtered data based on raw start and end dates
        try {
            const response = await fetch(`${apiURL}/api/get-exported-data?startDate=${startDate}&endDate=${endDate}`);
            const result = await response.json();
            setData(result); // Make sure result is the array of data
            console.log('Fetched Data:', result);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    } else {
        alert('Please select both start and end dates');
    }
};

    const generateYearOptions = () => {
        const options = [];
        for (let i = 2024; i <= 3000; i++) {
            options.push(<option key={i} value={i}>{i}</option>);
        }
        return options;
    };

 const handlePrint = () => {
    if (!billNo) {
      // If the bill number is not entered, show an alert and prevent the print
      window.alert('Please enter the Bill Number before printing.');
      return; // Stop the print function from running
    }
    
    // Proceed with the print if billNo is entered
    window.print();  // or whatever your print function is
  };
    const totalRSPS = useMemo(() => {
        return data.reduce((total, row) => total + parseFloat(row.RS_PS || 0), 0);
    }, [data]);

    const superTotal = totalRSPS + (totalRSPS * fuelExpense);
    const grandTotal = superTotal + (superTotal * cgstRate) + (superTotal * sgstRate);
    const CGST = superTotal * cgstRate;
    const SGST = superTotal * sgstRate;
    const FUEL = totalRSPS * fuelExpense;
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };



    const grandTotalInWords = numberToWords(Math.floor(grandTotal));

    const [companies, setCompanies] = useState([]);
const [selectedCompanyId, setSelectedCompanyId] = useState('');
const [companyDetails, setCompanyDetails] = useState(null);

// Fetch the list of companies when the component mounts
useEffect(() => {
  axios.get(`${apiURL }/companies`)
    .then((response) => {
      setCompanies(response.data);
    })
    .catch((error) => {
      console.error('Error fetching companies:', error);
    });
}, []);

// Fetch the details of the selected company
const [bankDetails, setBankDetails] = useState({
    accountName: "SENTHUR AGENCIES",
    bankName: "BANK OF BARODA",
    branch: "ANNUR BR.",
    accountNumber: "55060200000292",
    ifsc: "BARB0ANNURX"
  });
  
  const handleCompanyChange = (e) => {
    const companyId = e.target.value;
    setSelectedCompanyId(companyId);
  
    if (companyId) {
      axios.get(`${apiURL}/companies/${companyId}`)
        .then((response) => {
          const company = response.data;
  
          // Check the logo name to determine bank details
          if (company.logoName === "SHRL") {
            setBankDetails({
              accountName: "SRI HEMARUBA LOGISTICS",
              bankName: "HDFC BANK",
              branch: "ANNUR BR.",
              accountNumber: "50200099614684",
              ifsc: "HDFC0008753"
            });
          } else {
            setBankDetails({
              accountName: "SENTHUR AGENCIES",
              bankName: "BANK OF BARODA",
              branch: "ANNUR BR.",
              accountNumber: "55060200000292",
              ifsc: "BARB0ANNURX"
            });
          }
  
          setCompanyDetails(company);
        })
        .catch((error) => {
          console.error("Error fetching company details:", error);
        });
    } else {
      setCompanyDetails(null); // Clear company details
      setBankDetails(null); // Clear bank details
    }
  };
  

const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
const [selectedDate, setSelectedDate] = useState(getTodayDate()); // Default date is today

const formatDateToIndian = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const generateEmptyRowIndexes = (start, interval, maxRows) => {
    const indexes = [];
    for (let i = start; i <= maxRows; i += interval) {
        indexes.push(i);
    }
    return indexes;
};


const insertExtraRows = (data) => {
    const emptyRowIndexes = generateEmptyRowIndexes(43, 60, data.length);

    return data.flatMap((item, index) => {
        const row = (
            <tr key={`data-row-${index}`}>
                <td style={{ width: '5%', border:  '1px solid black', padding: '1px' }}>{index + 1}</td>
                <td style={{ width: '15%', border: '1px solid black', padding: '1px' }}>{formatDate(item.Date)}</td>
                <td style={{ width: '20%', border: '1px solid black', padding: '1px' }}>{item.Order_ID}</td>
                <td style={{ width: '20%', border: '1px solid black', padding: '1px' }}>{item.Cannote_No}</td>
                <td style={{ width: '20%', border: '1px solid black', padding: '1px' }}>{item.Destination}</td>
                <td style={{ width: '10%', border: '1px solid black', padding: '1px' }}>{item.Weight}</td>
                <td style={{ width: '10%', border: '1px solid black', padding: '1px' }}>{item.RS_PS.toFixed(2)}</td>
            </tr>
        );

        const extraRow = emptyRowIndexes.includes(index + 1) ? (
            <tr  key={`extra-row-${index}`} style={{ backgroundColor: '#f0f0f0', borderBottom: '1px solid black',borderLeft:'2px solid white',borderRight:'2px solid white',width:'80%'}}>
                <td colSpan="7" style={{ textAlign: 'center', padding: '22px' }}>
                </td>
            </tr>
        ) : null;

        return extraRow ? [row, extraRow] : [row];
    });
};
//edit
const handleEdit = (index) => {
    const row = data[index];
    setEditData({
        ...row, // Ensure the entire row data is copied, including Date
    }); 
    setIsEditing(true); // Switch to editing mode
    setSelectedRowIndex(index); // Keep track of which row is being edited
};


// Function to handle delete button click
const handleDelete = async (index) => {
    const row = data[index];
    try {
        // Send a request to delete the record using Cannote_No
        await axios.delete(`${apiURL}/api/delete-record`, { data: { Cannote_No: row.Cannote_No } });
        // After deleting, remove the row from the state
        setData(data.filter((_, i) => i !== index));
    } catch (error) {
        console.error('Error deleting record:', error);
    }
};

// Function to handle form submit (save changes after editing)
const handleSaveEdit = async () => {
    try {
      // Ensure the Date is formatted as yyyy-mm-dd (already done earlier)
      const formattedDate = formatDateToInput(editData.Date);
  
      // Send the request to update the record with the new Cannote_No as well
      await axios.put(`${apiURL}/api/update-record`, { 
        ...editData,
        Date: formattedDate,  // Ensure the Date is in the correct format
      });
  
      // Update the data state with the edited row
      const updatedData = [...data];
      updatedData[selectedRowIndex] = { ...editData, Date: formattedDate };
      setData(updatedData);
  
      // Exit editing mode
      setIsEditing(false);
      setEditData(null);
      setSelectedRowIndex(null);
    } catch (error) {
      console.error('Error saving edits:', error);
    }
  };
  

// Function to handle input changes while editing
const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditData((prevData) => ({ ...prevData, [name]: value })); // Spread the previous data and update the specific field
};


const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}-${month}-${year}`;  // Return the date in yyyy-mm-dd format
};

const formatDateToInput = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;  // Return the date in yyyy-mm-dd format
};

    return (
        <div className="main-content print-hidden">
            <div className="filter print-hidden">
                <h2>Report Filter</h2>
                <select value={month} onChange={handleMonthChange}>
                    <option value="">Select Month</option>
                    <option value="01">January</option>
                    <option value="02">February</option>
                    <option value="03">March</option>
                    <option value="04">April</option>
                    <option value="05">May</option>
                    <option value="06">June</option>
                    <option value="07">July</option>
                    <option value="08">August</option>
                    <option value="09">September</option>
                    <option value="10">October</option>
                    <option value="11">November</option>
                    <option value="12">December</option>
                </select>

                <select value={year} onChange={handleYearChange}>
                    <option value="">Select Year</option>
                    {generateYearOptions()}
                </select>

                <button onClick={() => handleDateRange('1-15')}>1 to 15 days</button>
                <button onClick={() => handleDateRange('16-31')}>16 to 31 days</button>
            </div>
            <div className='filter print-hidden'>
            <div className="filter2 print-hidden">
                <form onSubmit={handleSubmit}>
                <h2>Report Filter</h2>
                    <label>Start Date</label>
                    <input
                        type="date"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                    />
                    <label>End Date</label>
                    <input
                        type="date"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                    />
                    <button type="submit">Get Data</button>
                </form>
            </div>
            </div>
            <div style={{ marginTop: "20px"}} className="table print-hidden">
            <table>
                    <thead>
                        <tr>
                            <th>S.No</th>
                            <th>Date</th>
                            <th>Order_ID</th>
                            <th>Cannote_No</th>
                            <th>Destination</th>
                            <th>Weight</th>
                            <th>RS_PS</th>
                            <th>Actions</th>
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((item, index) => (
                            <tr key={index}>
                                <td>{index + 1}</td>
                                <td>
                                    {isEditing && selectedRowIndex === index ? (
                                        <input
                                            type="date"
                                            name="Date"
                                            // Convert the Date to 'yyyy-mm-dd' format for the input
                                            value={editData.Date ? formatDateToInput(editData.Date) : ''}
                                            onChange={handleInputChange}  // Update the state on change
                                        />
                                    ) : (
                                        // Display the date in 'dd-mm-yyyy' format when not editing
                                        formatDate(item.Date)
                                    )}
                                </td>

                                <td>{isEditing && selectedRowIndex === index ? (
                                    <input
                                        type="text"
                                        name="Order_ID"
                                        value={editData.Order_ID || ''}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    item.Order_ID
                                )}</td>
                                <td>{isEditing && selectedRowIndex === index ? (
                                    <input
                                        type="text"
                                        name="Cannote_No"
                                        value={editData.Cannote_No || ''}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    item.Cannote_No
                                )}</td>
                                <td>{isEditing && selectedRowIndex === index ? (
                                    <input
                                        type="text"
                                        name="Destination"
                                        value={editData.Destination || ''}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    item.Destination
                                )}</td>
                                <td>{isEditing && selectedRowIndex === index ? (
                                    <input
                                        type="number"
                                        name="Weight"
                                        value={editData.Weight || ''}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    item.Weight
                                )}</td>
                                <td>{isEditing && selectedRowIndex === index ? (
                                    <input
                                        type="number"
                                        name="RS_PS"
                                        value={editData.RS_PS || ''}
                                        onChange={handleInputChange}
                                    />
                                ) : (
                                    item.RS_PS
                                )}</td>
                                <td>
                                    {isEditing && selectedRowIndex === index ? (
                                        <button onClick={handleSaveEdit}>Save</button>
                                    ) : (
                                        <>
                                            <button onClick={() => handleEdit(index)}>Edit</button>
                                            <button onClick={() => handleDelete(index)}>Delete</button>
                                        </>
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            <button onClick={handleClickOpen} className='Exported print-hidden'>
                Show Exported Data
            </button>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
                <DialogContent>
                    <div className='exportData' style={{ padding: '20px', fontFamily: 'Arial, sans-serif'}}>
                    <select className="print-hidden" style={{padding:"5px",fontSize:"15px"}} value={selectedCompanyId} onChange={handleCompanyChange}>
          <option  value="">Select a company</option>
          {companies.map((company) => (
            <option key={company.id} value={company.id}>
              {company.logoName}
            </option>
          ))}
        </select>
            <div style={{ textAlign: 'center', marginBottom: '20px' ,fontSize:"15px",fontWeight:"800 !importent"}}>
            {companyDetails && (
        <div className="logo">
          <img 
            src={companyDetails.logoUrl || 'default-logo.png'} 
            alt="Company Logo" 
            style={{ width: '50%', height: '100px' }} 
          />
          <p style={{ textAlign: 'center',fontSize:"15px",fontWeight:"bolder"}}>{companyDetails.companyName}</p>
          <p style={{ textAlign: 'center',fontSize:"15px",fontWeight:"bolder"}}>{companyDetails.address}</p>
          <p style={{ textAlign: 'center',fontSize:"15px",fontWeight:"bolder"}}>Cell: {companyDetails.mobile1},{companyDetails.mobile2}</p>
          <p style={{ textAlign: 'center',fontSize:"15px",fontWeight:"bolder"}}>PAN No: {companyDetails.panNumber}, GST Number: {companyDetails.gstNumber}</p>
        </div>
      )}
            </div>

                        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                            <div>
                                <p><strong>TO:</strong></p>
                                <p>M/s. PREETHI DESIGNERS</p>
                                <p>132/3-B, AM COLONY</p>
                                <p>VOC STREET, ANNUR-641653</p>
                                <p>GSTIN: 33GQDPPS510D1ZR</p>
                            </div>
                            <div>
                            <p><strong>Date:</strong> {formatDateToIndian(selectedDate)}</p>
                                    <p className="print-hidden">
                                        <input
                                        type="date"
                                        value={selectedDate}
                                        onChange={(e) => setSelectedDate(e.target.value)}  // Update the selected date
                                        style={{ padding: "6px", fontSize: "15px" }}
                                        />
                                    </p>                               
                                <p><strong>BILL NO:</strong>{billNo}</p>
                                <p className="print-hidden"><input
                                value={billNo}
                                onChange={(e) => setBillNo(e.target.value)}
                                 style={{padding:"6px",fontSize:"15px",color:"red"}} 
                                 type='number' 
                                 placeholder='Enter the Bill No'></input></p>
                                <p><strong>PERIOD:</strong> {period}</p>
                                </div>
                        </div>

                                <table style={{ width: '99.9%', borderCollapse: 'collapse', marginBottom: '-20px' ,marginLeft:'1px'}}>
                                      <thead>
                                        <tr>
                                            <th style={{ fontSize :"12px",width: '5%',border: '1px solid black', padding: '1px' }}>S.No</th>
                                            <th style={{ fontSize :"12px",width: '15%', border: '1px solid black', padding: '1px' }}>Date</th>
                                            <th style={{ fontSize :"12px",width: '19.9%', border: '1px solid black', padding: '1px' }}>Order_ID</th>
                                            <th style={{ fontSize :"12px",width: '20.1%',  border: '1px solid black', padding: '1px' }}>Cannote_No</th>
                                            <th style={{ fontSize :"12px",width: '20%',  border: '1px solid black', padding: '1px' }}>Destination</th>
                                            <th style={{ fontSize :"12px",width: '10%',  border: '1px solid black', padding: '1px' }}>Weight</th>
                                            <th style={{ fontSize :"12px",width: '10%',  border: '1px solid black', padding: '1px' }}>RS_PS</th>
                                        </tr>
                                        </thead>
                                    </table>

                                {/* The table data itself */}
                                <table className='Datas' style={{ width: '100%', borderCollapse: 'collapse' }}>
                                        <tbody>
                                            {insertExtraRows(data)}
                                        </tbody>
                                </table>


                        <div className='summary-section'>
                        <div style={{ marginTop: '20px', textAlign: 'right' }}>
    {/* Showing TOTAL with 2 decimals */}
    <p><strong>TOTAL: </strong>{(totalRSPS).toFixed(2)}</p>

    {/* Showing FUEL SUR CHARGE with 2 decimals */}
    <p><strong>FUEL SUR CHARGE {Math.round(fuelExpense * 100 * 100) / 100}%: </strong>{Math.round(FUEL * 100) / 100}</p>

    {/* Showing SUPER TOTAL with 2 decimals */}
    <p className='supertotal'><strong>SUPER TOTAL: </strong>{(superTotal).toFixed(2)}</p>

    {/* Showing CGST with 2 decimals */}
    <p><strong>CGST {Math.round(cgstRate * 100 * 100) / 100}%: </strong>{Math.round(CGST * 100) / 100}</p>

    {/* Showing SGST with 2 decimals */}
    <p><strong>SGST {Math.round(sgstRate * 100 * 100) / 100}%: </strong>{Math.round(SGST * 100) / 100}</p>

    {/* Showing GRAND TOTAL with 2 decimals */}
    <p><strong>GRAND TOTAL: </strong>{(grandTotal).toFixed(2)}</p>

    {/* Displaying GRAND TOTAL in words */}
    <p style={{ color: "black", fontSize: "20px" }}>{grandTotalInWords}</p>
</div>


<div style={{ marginTop: '10px', textAlign: "center" }}>
  {bankDetails ? (
    <>
      <p>
        Issues cheque's or dd's {bankDetails.accountName}, {bankDetails.bankName}, {bankDetails.branch}.
      </p>
      <p>ACC NO: {bankDetails.accountNumber}, IFSC: {bankDetails.ifsc}.</p>
      <p>For {bankDetails.accountName}</p>
    </>
  ) : (
    <p>Please select a company to view bank details.</p>
  )}
  <p style={{ textAlign: 'center', paddingBottom: "30px", paddingTop: "5px" }}>PROPRIETOR</p>
  <h6 style={{ textAlign: "center", color: "black", fontSize: "12px" }}>
    THANK YOU FOR USING OUR SERVICES
  </h6>
</div>
                        </div>

                        <div className="print-hidden" style={{ marginTop: '20px' }}>
                        <TextField
                            label="Fuel Expense (%)"
                            type="number"
                            variant="outlined"
                            value={fuelExpense ? (fuelExpense * 100).toFixed() : ""} // Show rounded value for display only
                            onChange={(e) => {
                                const input = parseFloat(e.target.value) / 100;
                                setFuelExpense(isNaN(input) ? "" : input);  // Store clean, unrounded value in state
                            }}
                            style={{ marginRight: '10px', width: '200px', marginLeft: '40px' }}
                            />


                            <TextField
                                label="CGST Rate (%)"
                                type="number"
                                variant="outlined"
                                value={cgstRate ? (cgstRate * 100).toFixed() : ""} // Show rounded value for display only
                                onChange={(e) => {
                                const input = parseFloat(e.target.value) / 100;
                                setCgstRate(isNaN(input) ? "" : input);  // Store clean, unrounded value in state
                                }}
                                style={{ marginRight: '10px', width: '200px', marginLeft: '40px' }}
                            />

                            <TextField
                                label="SGST Rate (%)"
                                type="number"
                                variant="outlined"
                                value={sgstRate ? (sgstRate * 100).toFixed() : ""} // Show rounded value for display only
                                onChange={(e) => {
                                const input = parseFloat(e.target.value) / 100;
                                setSgstRate(isNaN(input) ? "" : input);  // Store clean, unrounded value in state
                                }}
                                style={{ marginRight: '10px', width: '200px', marginLeft: '40px' }}
                            />
                        </div>
                    </div>
                </DialogContent>
                <DialogActions className="print-hidden">
                    <Button onClick={handleClose} color="secondary" variant="contained">
                        Close
                    </Button>
                    <Button onClick={handlePrint} color="secondary" variant="contained" style={{marginRight:"70px"}} className='my'>
                        Print
                    </Button>
                </DialogActions>
            </Dialog>
            <style>{`
@media print {
    /* Page size and margins */
    @page {
        size: A4 portrait;
        margin: 20mm 20mm 30mm 20mm; /* Increase the top margin for every page */
    }

    /* General print settings */
    body {
        margin: 0 !important;
        padding: 0 !important;
        box-sizing: border-box;
    }

    table {
        width: 100%;
        border-bottom: 1px solid black !important;
    }

    .Datas td {
        border: none !important;
        font-size: 13px !important;
        font-weight: bold !important;
        font-family: Roboto !important;
        height: 20px !important;
    }

    /* Page break after first 45 rows */
    .Datas tr:nth-child(43) {
        page-break-after: always !important; /* Force a page break after the 45th row */
        border-bottom: 1px solid black !important;
    }

    /* Page break after every 66 rows (starting after row 45) */
    .Datas tr:nth-child(n+44):nth-child(61n+43) {
        page-break-after: always !important; /* Force a page break every 68 rows after row 45 */
        border-bottom: 1px solid black !important;
    }
    
            /* Page break after the last row in the table */
        .Datas tr:last-child {
            border-bottom: 1px solid black !important; /* Optional: adds a bottom border */
        }

    /* Optional: Borders between columns */
    .Datas th, .Datas td {
        border-left: 1px solid black !important;
        border-right: 1px solid black !important;
    }

    /* Hide elements that shouldn't be printed */
    .print-hidden {
        display: none !important;
    }

    /* Ensure table header and footer are repeated on each page */
    thead {
        display: table-header-group;
        border-bottom:1px solid black;
    }   

    tfoot {
        display: table-footer-group;
    }

    /* Control page breaks */
    .Datas tr {
        page-break-inside: avoid !important; /* Avoid breaking rows across pages */
    }

    .summary-section {
        page-break-inside: avoid !important;
        margin-top: 20px;
        text-align: right;
    }
}


`}
      </style>
        </div>
        
    );
}

export default ReportFilter;