import React, { useEffect, useState, useMemo } from 'react';
import axios from 'axios';
import Select from 'react-select'; 
import { Dialog, DialogContent, DialogActions, Button, TextField } from '@material-ui/core';
import apiURL from './Config';


function numberToWords(num) {
  const units = ["", "One", "Two", "Three", "Four", "Five", "Six", "Seven", "Eight", "Nine"];
  const teens = ["Ten", "Eleven", "Twelve", "Thirteen", "Fourteen", "Fifteen", "Sixteen", "Seventeen", "Eighteen", "Nineteen"];
  const tens = ["", "", "Twenty", "Thirty", "Forty", "Fifty", "Sixty", "Seventy", "Eighty", "Ninety"];
  
  if (num === 0) return "Zero Only";

  // Split the number into groups following the Indian numbering system
  const crore = Math.floor(num / 10000000);
  const lakh = Math.floor((num % 10000000) / 100000);
  const thousand = Math.floor((num % 100000) / 1000);
  const hundred = Math.floor((num % 1000) / 100);
  const tensAndUnits = num % 100;

  let result = "";

  // Handle crores
  if (crore > 0) {
    result += convertGroupToWords(crore, units, teens, tens) + " Crore ";
  }

  // Handle lakhs
  if (lakh > 0) {
    result += convertGroupToWords(lakh, units, teens, tens) + " Lakh ";
  }

  // Handle thousands
  if (thousand > 0) {
    result += convertGroupToWords(thousand, units, teens, tens) + " Thousand ";
  }

  // Handle hundreds
  if (hundred > 0) {
    result += units[hundred] + " Hundred ";
  }

  // Handle tens and units
  if (tensAndUnits > 0) {
    if (tensAndUnits < 10) {
      result += units[tensAndUnits] + " ";
    } else if (tensAndUnits < 20) {
      result += teens[tensAndUnits - 10] + " ";
    } else {
      const tensValue = Math.floor(tensAndUnits / 10);
      const unitsValue = tensAndUnits % 10;
      result += tens[tensValue] + " " + units[unitsValue] + " ";
    }
  }

  return result.trim() + " Only";
}

// Helper function to convert groups to words (for crores, lakhs, and thousands)
function convertGroupToWords(group, units, teens, tens) {
  if (group === 0) return "";

  let groupWord = "";
  if (group < 10) {
    groupWord += units[group];
  } else if (group < 20) {
    groupWord += teens[group - 10];
  } else {
    const tensValue = Math.floor(group / 10);
    const unitsValue = group % 10;
    groupWord += tens[tensValue] + " " + units[unitsValue];
  }

  return groupWord.trim();
}

function OtherClientsData() {
  const [data, setData] = useState([]);
  const [options, setOptions] = useState([]);
  const [clientId, setClientId] = useState(null); // Updated to use client ID
  const [month, setMonth] = useState('');
  const [year, setYear] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [open, setOpen] = useState(false);
  const [cgstRate, setCgstRate] = useState(0.09);
  const [sgstRate, setSgstRate] = useState(0.09);
  const [fuelExpense, setFuelExpense] = useState(0);
  const [billNo, setBillNo] = useState(''); // Create state for Bill No
  const [period, setPeriod] = useState(''); // State to store the PERIOD text
  const [editData, setEditData] = useState(null); // Track row data being edited
  const [isEditing, setIsEditing] = useState(false); // Track edit mode
  const [selectedRowIndex, setSelectedRowIndex] = useState(null); // Track selected row index

  
  useEffect(() => {
    axios.get(`${apiURL }/api/getClientOptions`)
      .then((response) => {
        console.log('Client options response:', response.data); 
        setOptions(response.data.map(client => ({ label: client.name, value: client.id })));
      })
      .catch((error) => {
        console.error('Error fetching client options:', error);
      });
  }, []);

  const fetchFilteredData = (filters) => {
    axios.post(`${apiURL }/api/getFilteredData`, filters)
    .then((response) => {
      console.log('Filtered data response:', response.data); 
      setData(response.data);
    })
    .catch((error) => {
      console.error('Error fetching filtered data:', error);
    });
  };

  const handleDateRange = (range) => {
    if (!clientId || !month || !year) {
      alert('Please select a client, month, and year.');
      return;
    }
  
    const start = new Date(year, month - 1, range === '1-15' ? 1 : 16);
    const end = new Date(year, month - 1, range === '1-15' ? 15 : new Date(year, month, 0).getDate());
  
    fetchFilteredData({
      clientId,
      startDate: start.toISOString().split('T')[0],
      endDate: end.toISOString().split('T')[0],
    });
  
    // Set the period text when a date range button is clicked
    const monthNames = [
      'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 
      'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
    ];
  
    const selectedMonth = monthNames[month - 1]; // Convert the month number to name
    const periodText = `${selectedMonth}-${year}-${range === '1-15' ? '1' : '2'}`;
    setPeriod(periodText);
  };
  
  const handleSubmit = (e) => {
    e.preventDefault();
  
    if (!clientId || !startDate || !endDate) {
      alert('Please select a client and date range.');
      return;
    }
  
    fetchFilteredData({
      clientId,
      startDate,
      endDate,
    });
  
    // Calculate and format the period text based on startDate and endDate
    const start = new Date(startDate);
    
    const monthNames = [
      'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 
      'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'
    ];
  
    const startMonth = monthNames[start.getMonth()];
    const startYear = start.getFullYear();
    
    const range = start.getDate() <= 15 ? '1' : '2';
    const periodText = `${startMonth}-${startYear}-${range}`;
    
    setPeriod(periodText);
  };

  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
   const handlePrint = () => {
    if (!billNo) {
      // If the bill number is not entered, show an alert and prevent the print
      window.alert('Please enter the Bill Number before printing.');
      return; // Stop the print function from running
    }
    
    // Proceed with the print if billNo is entered
    window.print();  // or whatever your print function is
  };
  
  const generateYearOptions = () => {
    const currentYear = new Date().getFullYear();
    let years = [];
    for (let i = 0; i < 100; i++) {
      years.push(currentYear + i);
    }
    return years.map(year => <option key={year} value={year}>{year}</option>);
  };

  // Calculate totals
  const totalRSPS = useMemo(() => {
    return data.reduce((total, row) => total + parseFloat(row.RS_PS || 0), 0);
}, [data]);

const superTotal = totalRSPS + (totalRSPS * fuelExpense);
const grandTotal = superTotal + (superTotal * cgstRate) + (superTotal * sgstRate);
const CGST = superTotal * cgstRate;
const SGST = superTotal * sgstRate;
const FUEL = totalRSPS * fuelExpense;



const handleClientSelection = (selectedOption) => {
  const selectedClientId = selectedOption?.value;
  setClientId(selectedClientId);

  // Fetch client details if a client is selected
  if (selectedClientId) {
      axios.post(`${apiURL }/api/getClientDetails`, { clientId: selectedClientId })
          .then((response) => {
              const { client_name, Address, GST_no } = response.data;
              
              // Set client details in the state
              setClientDetails({
                  name: client_name,
                  address: Address,
                  gst: GST_no
              });
          })
          .catch((error) => {
              console.error('Error fetching client details:', error);
          });
  }
};

// Example state to hold client details
const [clientDetails, setClientDetails] = useState({
  name: '',
  address: '',
  gst: ''
});

  

const grandTotalInWords = numberToWords(Math.floor(grandTotal));


const [companies, setCompanies] = useState([]);
const [selectedCompanyId, setSelectedCompanyId] = useState('');
const [companyDetails, setCompanyDetails] = useState(null);

// Fetch the list of companies when the component mounts
useEffect(() => {
  axios.get(`${apiURL }/companies`)
    .then((response) => {
      setCompanies(response.data);
    })
    .catch((error) => {
      console.error('Error fetching companies:', error);
    });
}, []);

// Fetch the details of the selected company
const [bankDetails, setBankDetails] = useState({
  accountName: "SENTHUR AGENCIES",
  bankName: "BANK OF BARODA",
  branch: "ANNUR BR.",
  accountNumber: "55060200000292",
  ifsc: "BARB0ANNURX"
});

const handleCompanyChange = (e) => {
  const companyId = e.target.value;
  setSelectedCompanyId(companyId);

  if (companyId) {
    axios.get(`${apiURL}/companies/${companyId}`)
      .then((response) => {
        const company = response.data;

        // Check the logo name to determine bank details
        if (company.logoName === "SHRL") {
          setBankDetails({
            accountName: "SRI HEMARUBA LOGISTICS",
            bankName: "HDFC BANK",
            branch: "ANNUR BR",
            accountNumber: "50200099614684",
            ifsc: "HDFC0008753"
          });
        } else {
          setBankDetails({
            accountName: "SENTHUR AGENCIES",
            bankName: "BANK OF BARODA",
            branch: "ANNUR BR.",
            accountNumber: "55060200000292",
            ifsc: "BARB0ANNURX"
          });
        }

        setCompanyDetails(company);
      })
      .catch((error) => {
        console.error("Error fetching company details:", error);
      });
  } else {
    setCompanyDetails(null); // Clear company details
    setBankDetails(null); // Clear bank details
  }
};


const getTodayDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so we add 1
  const day = String(today.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};
const [selectedDate, setSelectedDate] = useState(getTodayDate()); // Default date is today

const formatDateToIndian = (dateString) => {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

const generateEmptyRowIndexes = (start, interval, maxRows) => {
  const indexes = [];
  for (let i = start; i <= maxRows; i += interval) {
      indexes.push(i);
  }
  return indexes;
};


const insertExtraRows = (data) => {
  const emptyRowIndexes = generateEmptyRowIndexes(43, 60, data.length);

  return data.flatMap((item, index) => {
      const row = (
          <tr key={`data-row-${index}`}>
                    <td style={{fontSize:"15px", width: '4.9%', padding: '1px' }}>{index + 1}</td>
                    <td style={{fontSize:"15px",  width: '14.9%', padding: '1px' }}>{formatDate(item.Date)}</td>
                    <td style={{fontSize:"15px",  width: '15.5%', padding: '1px' }}>{item.Cannote_No}</td>
                    <td style={{fontSize:"15px",  width: '19.7%', padding: '1px' }}>{item.Destination}</td>
                    <td style={{fontSize:"15px",  width: '9.9%', padding: '1px' }}>{Math.floor(item.Weight)}</td>
                    <td style={{fontSize:"15px",  width: '9.9%', padding: '1px' }}>{Math.floor(item.RS_PS).toFixed(2)}</td>
          </tr>
      );

      const extraRow = emptyRowIndexes.includes(index + 1) ? (
          <tr  key={`extra-row-${index}`} style={{ backgroundColor: '#f0f0f0', borderBottom: '1px solid black',borderLeft:'2px solid white',borderRight:'2px solid white',width:'80%'}}>
              <td colSpan="7" style={{ textAlign: 'center', padding: '22px' }}>
              </td>
          </tr>
      ) : null;

      return extraRow ? [row, extraRow] : [row];
  });
};
//edit
const handleEdit = (index) => {
  const row = data[index];
  setEditData({
      ...row, // Ensure the entire row data is copied, including Date
  }); 
  setIsEditing(true); // Switch to editing mode
  setSelectedRowIndex(index); // Keep track of which row is being edited
};


// Function to handle delete button click
const handleDelete = async (index) => {
  const row = data[index];
  try {
      // Send a request to delete the record using Cannote_No
      await axios.delete(`${apiURL}/api/invoice/delete-record`, { data: { Cannote_No: row.Cannote_No } });
      // After deleting, remove the row from the state
      setData(data.filter((_, i) => i !== index));
  } catch (error) {
      console.error('Error deleting record:', error);
  }
};

// Function to handle form submit (save changes after editing)
const handleSaveEdit = async () => {
  try {
    // Ensure the Date is formatted as yyyy-mm-dd (already done earlier)
    const formattedDate = formatDateToInput(editData.Date);

    // Send the request to update the record with the new Cannote_No as well
    await axios.put(`${apiURL}/api/invoice/update-record`, { 
      ...editData,
      Date: formattedDate,  // Ensure the Date is in the correct format
    });

    // Update the data state with the edited row
    const updatedData = [...data];
    updatedData[selectedRowIndex] = { ...editData, Date: formattedDate };
    setData(updatedData);

    // Exit editing mode
    setIsEditing(false);
    setEditData(null);
    setSelectedRowIndex(null);
  } catch (error) {
    console.error('Error saving edits:', error);
  }
};


// Function to handle input changes while editing
const handleInputChange = (e) => {
  const { name, value } = e.target;
  setEditData((prevData) => ({ ...prevData, [name]: value })); // Spread the previous data and update the specific field
};
const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${day}-${month}-${year}`;  // Return the date in yyyy-mm-dd format
};

const formatDateToInput = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;  // Return the date in yyyy-mm-dd format
};
  return (
    <div className="main-content print-hidden">
      <div className="filter">
        <h2>Report Filter</h2>
        <Select
          options={options}
          label="Select client name"
          onChange={handleClientSelection}
        />
        <select value={month} onChange={(e) => setMonth(e.target.value)}>
          <option value="">Select Month</option>
          <option value="01">January</option>
          <option value="02">February</option>
          <option value="03">March</option>
          <option value="04">April</option>
          <option value="05">May</option>
          <option value="06">June</option>
          <option value="07">July</option>
          <option value="08">August</option>
          <option value="09">September</option>
          <option value="10">October</option>
          <option value="11">November</option>
          <option value="12">December</option>
        </select>

        <select value={year} onChange={(e) => setYear(e.target.value)}>
          <option value="">Select Year</option>
          {generateYearOptions()}
        </select>

        <button onClick={() => handleDateRange('1-15')}>1 to 15 days</button>
        <button onClick={() => handleDateRange('16-31')}>16 to 31 days</button>
      </div>
      <div className='filter print-hidden'>
        <div className="filter2 print-hidden">
          <form onSubmit={handleSubmit}>
            <h2>Report Filter</h2>
            <Select
              options={options}
              label="Select client name"
              onChange={handleClientSelection}
              />
             <label>Start Date</label>
    <input
      type="date"
      value={startDate}
      onChange={(e) => setStartDate(e.target.value)}
    />
    <label>End Date</label>
    <input
      type="date"
      value={endDate}
      onChange={(e) => setEndDate(e.target.value)}
    />
    <button type="submit">Get Data</button>
          </form>
        </div>
      </div>
      <div style={{ marginTop: "40px" }} className="table">
      <table>
        <thead>
          <tr>
            <th>S.No</th>
            <th>Date</th>
            <th>Cannote_No</th>
            <th>Destination</th>
            <th>Weight</th>
            <th>RS_PS</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {data.map((item, index) => (
            <tr key={item.id}>
              <td>{index + 1}</td>
              <td>
                                    {isEditing && selectedRowIndex === index ? (
                                        <input
                                            type="date"
                                            name="Date"
                                            // Convert the Date to 'yyyy-mm-dd' format for the input
                                            value={editData.Date ? formatDateToInput(editData.Date) : ''}
                                            onChange={handleInputChange}  // Update the state on change
                                        />
                                    ) : (
                                        // Display the date in 'dd-mm-yyyy' format when not editing
                                        formatDate(item.Date)
                                    )}
                                </td>
              <td>
                {isEditing && selectedRowIndex === index ? (
                  <input
                    type="text"
                    name="Cannote_No"
                    value={editData.Cannote_No}
                    onChange={handleInputChange}
                    disabled
                  />
                ) : (
                  item.Cannote_No
                )}
              </td>
              <td>
                {isEditing && selectedRowIndex === index ? (
                  <input
                    type="text"
                    name="Destination"
                    value={editData.Destination}
                    onChange={handleInputChange}
                  />
                ) : (
                  item.Destination
                )}
              </td>
              <td>
                {isEditing && selectedRowIndex === index ? (
                  <input
                    type="number"
                    name="Weight"
                    value={editData.Weight}
                    onChange={handleInputChange}
                  />
                ) : (
                  Math.round(item.Weight) // Round the value when not editing
                )}
              </td>

              <td>
                {isEditing && selectedRowIndex === index ? (
                  <input
                    type="number"
                    name="RS_PS"
                    value={editData.RS_PS}
                    onChange={handleInputChange}
                  />
                ) : (
                  Math.round(item.RS_PS) // Round the value when not editing
                )}
              </td>

              <td>
                {isEditing && selectedRowIndex === index ? (
                  <button onClick={handleSaveEdit}>Save</button>
                ) : (
                  <>
                    <button onClick={() => handleEdit(index)}>Edit</button>
                    <button onClick={() => handleDelete(index)}>Delete</button>
                  </>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>

      <button onClick={handleClickOpen} className='Exported'>
        Show Exported Data
      </button>

      <Dialog open={open} onClose={handleClose} fullWidth maxWidth="lg">
        <DialogContent>
          <div className='exportData' style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
        <select className="print-hidden" style={{padding:"5px",fontSize:"15px"}} value={selectedCompanyId} onChange={handleCompanyChange}>
          <option  value="">Select a company</option>
          {companies.map((company) => (
            <option key={company.id} value={company.id}>
              {company.logoName}
            </option>
          ))}
        </select>
            <div style={{ textAlign: 'center', marginBottom: '20px' ,fontSize:"15px",fontWeight:"800 !importen"}}>
            {companyDetails && (
        <div className="logo">
          <img 
            src={companyDetails.logoUrl || 'default-logo.png'} 
            alt="Company Logo" 
            style={{ width: '500px', height: '100px' }} 
          />
          <p style={{ textAlign: 'center',fontSize:"15px",fontWeight:"bolder"}}>{companyDetails.companyName}</p>
          <p style={{ textAlign: 'center',fontSize:"15px",fontWeight:"bolder"}}>{companyDetails.address}</p>
          <p style={{ textAlign: 'center',fontSize:"15px",fontWeight:"bolder"}}>Cell: {companyDetails.mobile1},{companyDetails.mobile2}</p>
          <p style={{ textAlign: 'center',fontSize:"15px",fontWeight:"bolder"}}>PAN No: {companyDetails.panNumber}, GST Number: {companyDetails.gstNumber}</p>
        </div>
      )}
            </div>

            <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
              <div>
              <p><strong>TO:</strong></p>
              <p>{clientDetails.name}</p>
               <p>{clientDetails.address}</p>
               <p>GST No:{clientDetails.gst}</p>
              </div>
              <div>
      {/* Date Section */}
      <p><strong>Date:</strong> {formatDateToIndian(selectedDate)}</p>
      <p className="print-hidden">
        <input
          type="date"
          value={selectedDate}
          onChange={(e) => setSelectedDate(e.target.value)}  // Update the selected date
          style={{ padding: "6px", fontSize: "15px" }}
        />
      </p>

      {/* Bill No Section */}
      <p><strong>Bill No:</strong> {billNo}</p>
      <p className="print-hidden">
        <input
          style={{ padding: "6px", fontSize: "15px", color: "red" }}
          type="number"
          placeholder="Enter the Bill No"
          value={billNo}
          onChange={(e) => setBillNo(e.target.value)}
        />
      </p>

      {/* Period Section */}
      <p><strong>PERIOD:</strong> {period}</p>
    </div>
            </div>

            <table style={{ width: '99.9%', borderCollapse: 'collapse', marginBottom: '-20px',marginLeft:'1px' }}>
                                        <thead>
                                        <tr>
                                            <th style={{fontSize :"14px", width: '3.7%',border: '1px solid black', padding: '1px' }}>S.No</th>
                                            <th style={{fontSize :"14px", width: '15.2%', border: '1px solid black', padding: '1px' }}>Date</th>
                                            <th style={{fontSize :"14px",width: '15.7%',  border: '1px solid black', padding: '1px' }}>Cannote_No</th>
                                            <th style={{fontSize :"14px", width: '20%',  border: '1px solid black', padding: '1px' }}>Destination</th>
                                            <th style={{fontSize :"14px", width: '10.1%',  border: '1px solid black', padding: '1px' }}>Weight</th>
                                            <th style={{fontSize :"14px", width: '10.1%',  border: '1px solid black', padding: '1px' }}>RS_PS</th>
                                        </tr>
                                        </thead>
                                    </table>

                                    <table className='Datas' style={{ width: '100%', borderCollapse: 'collapse' }}>
                                        <tbody>
                                            {insertExtraRows(data)}
                                        </tbody>
                                </table>
            <div className='summary-section'>
            <div style={{ marginTop: '20px', textAlign: 'right' }}>
    {/* Showing TOTAL with 2 decimals */}
    <p><strong>TOTAL: </strong>{(totalRSPS).toFixed(2)}</p>

    {/* Showing FUEL SUR CHARGE with 2 decimals */}
    <p><strong>FUEL SUR CHARGE {Math.round(fuelExpense * 100 * 100) / 100}%: </strong>{Math.round(FUEL * 100) / 100}</p>

    {/* Showing SUPER TOTAL with 2 decimals */}
    <p className='supertotal'><strong>SUPER TOTAL: </strong>{(superTotal).toFixed(2)}</p>

    {/* Showing CGST with 2 decimals */}
    
    <p><strong>CGST {Math.round(cgstRate * 100 * 100) / 100}%: </strong>{Math.round(CGST * 100) / 100}</p>

    {/* Showing SGST with 2 decimals */}
    <p><strong>SGST {Math.round(sgstRate * 100 * 100) / 100}%: </strong>{Math.round(SGST * 100) / 100}</p>

    {/* Showing GRAND TOTAL with 2 decimals */}
    <p><strong>GRAND TOTAL: </strong>{(grandTotal).toFixed(2)}</p>

    {/* Displaying GRAND TOTAL in words */}
    <p style={{ color: "black", fontSize: "20px" }}>{grandTotalInWords}</p>
</div>

<div style={{ marginTop: '10px', textAlign: "center" }}>
  {bankDetails ? (
    <>
      <p>
        Issues cheque's or dd's {bankDetails.accountName}, {bankDetails.bankName}, {bankDetails.branch}.
      </p>
      <p>ACC NO: {bankDetails.accountNumber}, IFSC: {bankDetails.ifsc}.</p>
      <p>For {bankDetails.accountName}</p>
    </>
  ) : (
    <p>Please select a company to view bank details.</p>
  )}
  <p style={{ textAlign: 'center', paddingBottom: "30px", paddingTop: "5px" }}>PROPRIETOR</p>
  <h6 style={{ textAlign: "center", color: "black", fontSize: "12px" }}>
    THANK YOU FOR USING OUR SERVICES
  </h6>
</div>

            </div>
            <div className="print-hidden" style={{ marginTop: '40px' }}>
            <TextField
              label="Fuel Expense (%)"
              type="number"
              variant="outlined"
              value={fuelExpense ? (fuelExpense * 100).toFixed() : ""} // Show rounded value for display only
              onChange={(e) => {
                const input = parseFloat(e.target.value) / 100;
                setFuelExpense(isNaN(input) ? "" : input);  // Store clean, unrounded value in state
              }}
              style={{ marginRight: '10px', width: '200px', marginLeft: '40px' }}
            />


            <TextField
                label="CGST Rate (%)"
                type="number"
                variant="outlined"
                value={cgstRate ? (cgstRate * 100).toFixed() : ""} // Show rounded value for display only
                onChange={(e) => {
                  const input = parseFloat(e.target.value) / 100;
                  setCgstRate(isNaN(input) ? "" : input);  // Store clean, unrounded value in state
                }}
                style={{ marginRight: '10px', width: '200px', marginLeft: '40px' }}
              />

              <TextField
                label="SGST Rate (%)"
                type="number"
                variant="outlined"
                value={sgstRate ? (sgstRate * 100).toFixed() : ""} // Show rounded value for display only
                onChange={(e) => {
                  const input = parseFloat(e.target.value) / 100;
                  setSgstRate(isNaN(input) ? "" : input);  // Store clean, unrounded value in state
                }}
                style={{ marginRight: '10px', width: '200px', marginLeft: '40px' }}
              />
            </div>
          </div>
        </DialogContent>
        <DialogActions className="print-hidden">
          <Button onClick={handleClose} color="secondary" variant="contained">
            Close
          </Button>
          <Button onClick={handlePrint} color="secondary" variant="contained" style={{marginRight:"70px"}} className='my'>
            Print
          </Button>
        </DialogActions>
      </Dialog>
      <style>{`
 @media print {
  /* Page size and margins */
  @page {
      size: A4 portrait;
      margin: 20mm 20mm 30mm 20mm; /* Increase the top margin for every page */
  }

  /* General print settings */
  body {
      margin: 0 !important;
      padding: 0 !important;
      box-sizing: border-box;
  }

  table {
      width: 100%;
      border-bottom: 1px solid black !important;
  }

  .Datas td {
      border: none !important;
      font-size: 13px !important;
      font-weight: bold !important;
      font-family: Roboto !important;
      height: 20px !important;
  }

  /* Page break after first 45 rows */
  .Datas tr:nth-child(43) {
      page-break-after: always !important; /* Force a page break after the 45th row */
      border-bottom: 1px solid black !important;
  }

  /* Page break after every 66 rows (starting after row 45) */
  .Datas tr:nth-child(n+44):nth-child(61n+43) {
      page-break-after: always !important; /* Force a page break every 68 rows after row 45 */
      border-bottom: 1px solid black !important;
  }

      .Datas tr:last-child {
        border-bottom: 1px solid black !important; /* Optional: adds a bottom border */
    }
  
  /* Optional: Borders between columns */
  .Datas th, .Datas td {
      border-left: 1px solid black !important;
      border-right: 1px solid black !important;
  }

  /* Hide elements that shouldn't be printed */
  .print-hidden {
      display: none !important;
  }

  /* Ensure table header and footer are repeated on each page */
  thead {
      display: table-header-group;
      border-bottom:1px solid black;
  }   

  tfoot {
      display: table-footer-group;
  }

  /* Control page breaks */
  .Datas tr {
      page-break-inside: avoid !important; /* Avoid breaking rows across pages */
  }
  .summary-section {
    page-break-inside: avoid !important;
    margin-top: 20px;
    text-align: right;
}
}
`}
      </style>
    </div>
  );
}

export default OtherClientsData;
